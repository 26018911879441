import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import PaymentLog from "../pages/PaymentLog/PaymentLog";

import { getAccessToken } from "../services/token";
import { getUser } from "../utils/storage";

export default function SubscriptionRoute() {
  const user = getUser();
  const navigate = useNavigate();

  useEffect(() => {
    const access = getAccessToken();
    if (!access || (user?.role === "teacher" && user?.phone !== "9802369440")) {
      navigate("/");
    }
  }, [navigate, user]);

  return (
    <Routes>
      <Route index element={<PaymentLog />} />
    </Routes>
  );
}
