import React from "react";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { PATH } from "../../constants/routes";
import { SYSTEM_ACCESS_ID } from "../../constants/accessId";

import "./Sidebar.scss";
import logo from "../../assets/images/name_online.png";

import { getUser } from "../../utils/storage";

type sidebarNavItemType = {
  to: string;
  display: string;
  section: string;
  accessId: string;
  icon: React.ReactElement;
};

const sidebarNavItems: sidebarNavItemType[] = [
  {
    display: "Dashboard",
    icon: <i className="bx bx-home"></i>,
    to: PATH.DASHBOARD,
    section: "",
    accessId: SYSTEM_ACCESS_ID.DASHBOARD
  },
  {
    display: "Users",
    icon: <i className="bx bx-group"></i>,
    to: PATH.USER,
    section: "users",
    accessId: SYSTEM_ACCESS_ID.USER
  },
  {
    display: "Instructors",
    icon: (
      <i
        className="far fa-chalkboard-teacher"
        style={{ fontSize: "0.9em" }}
      ></i>
    ),
    to: PATH.INSTRUCTOR,
    section: "instructors",
    accessId: SYSTEM_ACCESS_ID.INSTRUCTOR
  },
  {
    display: "Categories",
    icon: <i className="bx bx-layer"></i>,
    to: PATH.COURSE_GROUP,
    section: "coursegroups",
    accessId: SYSTEM_ACCESS_ID.COURSE_GROUP
  },
  {
    display: "Courses",
    icon: <i className="bx bx-book-reader"></i>,
    to: PATH.COURSE,
    section: "courses",
    accessId: SYSTEM_ACCESS_ID.COURSE
  },
  {
    display: "Subjects",
    icon: <i className="bx bx-book"></i>,
    to: PATH.SUBJECT,
    section: "subjects",
    accessId: SYSTEM_ACCESS_ID.SUBJECT
  },
  {
    display: "Units",
    icon: <i className="bx bx-book-open"></i>,
    to: PATH.UNIT,
    section: "units",
    accessId: SYSTEM_ACCESS_ID.UNIT
  },
  {
    display: "Chapters",
    icon: <i className="bx bx-align-middle"></i>,
    to: PATH.CHAPTER,
    section: "chapters",
    accessId: SYSTEM_ACCESS_ID.CHAPTER
  },
  {
    display: "Test Series",
    icon: <i className="bx bx-select-multiple"></i>,
    to: PATH.TESTSERIES,
    section: "testseries",
    accessId: SYSTEM_ACCESS_ID.TESTSERIES
  },
  {
    display: "Quizzes",
    icon: <i className="bx bx-select-multiple"></i>,
    to: PATH.QUIZ,
    section: "quizzes",
    accessId: SYSTEM_ACCESS_ID.QUIZ
  },
  {
    display: "Subscriptions",
    icon: <i className="bx bx-credit-card"></i>,
    to: PATH.SUBSCRIPTION,
    section: "subscription",
    accessId: SYSTEM_ACCESS_ID.SUBSCRIPTION
  },
  {
    display: "Referral Codes",
    icon: <i className="bx bx-font"></i>,
    to: PATH.REFERRAL,
    section: "referrals",
    accessId: SYSTEM_ACCESS_ID.REFERRAL
  },
  {
    display: "Payment Logs",
    icon: <i className="bx bx-money-withdraw"></i>,
    to: PATH.PAYMENTLOG,
    section: "paymentlogs",
    accessId: SYSTEM_ACCESS_ID.PAYMENTLOG
  },
  {
    display: "Notifications",
    icon: <i className="bx bx-bell"></i>,
    to: PATH.NOTIFICATION,
    section: "notifications",
    accessId: SYSTEM_ACCESS_ID.NOTIFICATION
  },
  {
    display: "Feedbacks",
    icon: <i className="bx bx-comment-detail"></i>,
    to: PATH.FEEDBACK,
    section: "feedbacks",
    accessId: SYSTEM_ACCESS_ID.FEEDBACK
  },
  {
    display: "Live",
    icon: <i className="bx bx-video"></i>,
    to: PATH.GROUP,
    section: "group",
    accessId: SYSTEM_ACCESS_ID.GROUP
  }
];

const Sidebar = () => {
  const user = getUser();
  const currentYear = new Date().getFullYear();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const location = useLocation();

  // change active index
  useEffect(() => {
    const curPath = window.location.pathname.split("/")[1];
    const activeItem = sidebarNavItems.findIndex(
      item => item.section === curPath
    );
    setActiveIndex(curPath.length === 0 ? 0 : activeItem);
  }, [location]);

  const filteredSideNavItems = (user?.role === "teacher" && user?.phone !== "9802369440") ? sidebarNavItems.filter(item => item.accessId !== SYSTEM_ACCESS_ID.PAYMENTLOG) : sidebarNavItems;

  function renderSidebarLists() {
    return filteredSideNavItems.map((item, index) => {
      return (
        <>
          {index === 0 ? (
            user && user.role === "administration" || user?.phone === "9802369440" ? (
              <Link to={item.to} key={index}>
                <div
                  className={`sidebar_menu_item ${
                    activeIndex === index ? "active" : ""
                  }`}
                >
                  <div
                    className={`sidebar_menu_item_icon ${
                      activeIndex === index ? "active" : ""
                    }`}
                  >
                    {item.icon}
                  </div>
                  <div
                    className={`sidebar_menu_item_text ${
                      activeIndex === index ? "active" : ""
                    }`}
                  >
                    {item.display}
                  </div>
                </div>
              </Link>
            ) : (
              <></>
            )
          ) : (
            <Link to={item.to} key={index}>
              <div
                className={`sidebar_menu_item ${
                  activeIndex === index ? "active" : ""
                }`}
              >
                <div
                  className={`sidebar_menu_item_icon ${
                    activeIndex === index ? "active" : ""
                  }`}
                >
                  {item.icon}
                </div>
                <div
                  className={`sidebar_menu_item_text ${
                    activeIndex === index ? "active" : ""
                  }`}
                >
                  {item.display}
                </div>
              </div>
            </Link>
          )}
        </>
      );
    });
  }

  return (
    <div className="sidebar">
      <div>
        <div className="sidebar_logo">
          <Link className="nameonline" to="/">
            <img className="sidebar_logo_image" src={logo} alt="logo" />
          </Link>
        </div>
        <div ref={sidebarRef} className="sidebar_menu">
          {renderSidebarLists()}
        </div>
      </div>

      <div className="sidebar_footer">
        <p>
          © {currentYear}{" "}
          <a target={"_blank"} rel="noreferrer" href="https://name.avyaas.com/">
            Name Online
          </a>
          . All Rights Reserved
        </p>
        <p>
          Powered by{" "}
          <a target={"_blank"} rel="noreferrer" href="https://ayata.com.np/">
            Ayata Inc.
          </a>
        </p>
      </div>
    </div>
  );
};

export default Sidebar;
